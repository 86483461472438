/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  Image,
  Pressable,
  ActivityIndicator,
  Dimensions,
  Clipboard
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";
import { Hoverable } from "react-native-web-hover";
import LinearGradient from 'react-native-web-linear-gradient';
import { SketchPicker } from 'react-color'

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'

import Scores from './matches/scores.js'

import Banner from '../components/widgets/scores.js'
import Tournaments from '../components/widgets/tournaments.js'

import Match from '../components/matches/match.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      activeView:'Live',
      active_tab:'tour',
      components:{},
      latest:[],
      accent:'rgba(202,243,76,1)',
      backgroundColor:'black',
      color_border:'rgba(0,0,0,0.04)',
      color:"black",
      color_view_all:"white",
      accent_1:'red',
      color:'black',
      hide_top_border:true,
      id:this.props.route?.params?.id
    }
  }

  async componentDidMount(){
    GLOBAL.navigation = this.props.navigation

    this.updateURL()

    //Call API
    // var params = {
    //   mode:'news',
    // }
    //
    // var data_r = await Functions.tnnsAPI(params)
    // var { data } = data_r
    // var latest = data?.latest || []
    //
    // this.setState({latest, loading:false})

  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  //


  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Free tennis widgets - Get live score, results & schedule free tennis widgets for your site - TNNS Live"
    var subtitle = "Take advantage of TNNS' free tennis widgets for live scores, results, highlight videos and more smart widgets designed to provide full coverage of any tennis event!"
    return(
      <>
      <SEOTitle title={title}/>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={subtitle}/>
      </Helmet>
      <Background navigation={this.props.navigation} page="Widget"
      // title='Free widgets'
      // icon={require('../assets/icons/ball.png')}
      >
        {loading ? this.renderLoading() : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { activeView, latest } = this.state

    var title_style = {fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding*3, marginBottom:GLOBAL.padding/2, textTransform:'uppercase'}

    return(
      <View style={[{width:'100%', padding:GLOBAL.padding, borderTopRightRadius:0, borderBottomRightRadius:0, backgroundColor:'white'}, MainStyles.flexCenterStart]}>
        {
          // <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:24}}>
          // Get free tennis widgets for your website
          // </Text>
        }
        <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:16, marginTop:GLOBAL.padding / 2, opacity:1, lineHeight:24}}>
        Build your own widget
        {"\n\n"}
        These widgets can be customized to match the styling of your site.  To get your own free widget tap "Customize your widget" on either of the widgets below.
        </Text>

        {
          <>
          <Text style={[title_style]}>
          HOW IT WORKS
          </Text>
          <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, lineHeight:24}}>
          {"1. Using the color tools below, select the colors that you'd like to use for your widget\n2. Once your happy with the design, copy the iframe src value (this is the link for your widget)\n3. Add the widget to your site by using an inline frame (an iframe)"}
          </Text>
          </>
        }

        <Text style={[title_style]}>
        1. Customize your colors
        </Text>

        <Text style={{color:GLOBAL.style.color, fontSize:13, fontFamily:GLOBAL.fonts.regular, marginBottom:GLOBAL.padding/2, marginTop:GLOBAL.padding/4}}>
        Tap on a color to edit it
        </Text>

        {
          this.state.id === 'scores' ?
          <>
          <View style={[{width:'100%', flexWrap:'wrap', marginTop:GLOBAL.padding/1.5}, MainStyles.flexRow, MainStyles.flexStartStart]}>
          {this.renderColorPicker('Accent', 'accent', this.state.accent)}
          {this.renderColorPicker('Background', 'backgroundColor', this.state.backgroundColor)}
          {this.renderColorPicker('Tour color', 'color', this.state.color)}
          {this.renderColorPicker('View all color', 'color_view_all', this.state.color_view_all)}
          </View>
          {
            this.state.show_picker ?
            <>
            <SketchPicker
              color={ this.state[this.state.show_picker] }
              onChangeComplete={(color)=>this.handleChangeComplete(this.state.show_picker, color) }
            />
            </>
            : null
          }
          </>
          :
          <>
          <View style={[{width:'100%', flexWrap:'wrap', marginTop:GLOBAL.padding/1.5}, MainStyles.flexRow, MainStyles.flexStartStart]}>
          {this.renderColorPicker('Accent', 'accent', this.state.accent)}
          {this.renderColorPicker('Accent 1', 'accent_1', this.state.backgroundColor)}
          {this.renderColorPicker('Color', 'color', this.state.color)}
          </View>
          {
            this.state.show_picker ?
            <>
            <SketchPicker
              color={ this.state[this.state.show_picker] }
              onChangeComplete={(color)=>this.handleChangeComplete(this.state.show_picker, color) }
            />
            </>
            : null
          }
          </>
        }


        <Text style={[title_style, {fontFamily:GLOBAL.fonts.medium, fontSize:13}]}>
        Widget preview
        </Text>
        {this.state.id === 'scores' ?
        <Banner style={{
          accent:this.state.accent,
          backgroundColor:this.state.backgroundColor,
          color_border:this.state.color_border,
          hide_top_border:this.state.hide_top_border,
          color:this.state.color,
          color_view_all:this.state.color_view_all,
        }} external/> :
        <Scores style={this.state.style} external style={{
          accent:this.state.accent,
          accent_1:this.state.accent_1,
          color:this.state.color,
        }}/>}

        <Text style={[title_style]}>
        2. Copy your src link
        </Text>

        <Text numberOfLines={1} style={{color:GLOBAL.style.color, flex:1, fontSize:13, fontFamily:GLOBAL.fonts.regular, marginBottom:GLOBAL.padding/2, marginTop:GLOBAL.padding/4, flexWrap:'wrap', textWrap:'wrap'}}>
        {this.state.url}
        </Text>

        {this.renderButton("Copy src link", ()=>this.copyToClipboard(this.state.url))}

        <Text style={[title_style]}>
        3. Embed within an iframe
        </Text>
        {this.renderButton("More info on iframes", ()=>window.open("https://www.w3schools.com/tags/tag_iframe.ASP", '_blank'))}

      </View>
    )
  }

  handleChangeComplete = async (key, color) => {
    var { style } = this.state

    // alert(JSON.stringify(color))
    const rgba = "rgba("+color.rgb.r+","+color.rgb.g+","+color.rgb.b+","+color.rgb.a+")"

    await this.setState({[key]:rgba})
    this.updateURL()
  };

  updateURL = () => {
    const { id } = this.state
    var url = ''
    if(id === 'scores'){
      const url_params = {
        accent:this.state.accent,
        backgroundColor:this.state.backgroundColor,
        color_border:this.state.color_border,
        hide_top_border:this.state.hide_top_border,
        color:this.state.color,
        color_view_all:this.state.color_view_all,
      }

      url = "localhost:3000/widgets/scores?style="+JSON.stringify(url_params)
      url = url.split('"').join("-")
    }else{
      url = ""
    }
    this.setState({url})
  }

  copyToClipboard = (string) => {
    Clipboard.setString(string)
    alert("Link copied to your clipboard")
  }

  renderColorPicker = (title, key, value) => {
    const show_picker = this.state.show_picker === key
    return(
      <View style={[{marginRight:GLOBAL.padding/2}]}>
        <View style={[{borderRadius:7, borderWidth:1, borderColor:'black', marginBottom:GLOBAL.padding/2}, MainStyles.flexStartCenter]}>
        {
          !show_picker ? null :
          <View style={[{paddingHorizontal:GLOBAL.padding/2, height:20, backgroundColor:'black', position:'absolute', top:-10, zIndex:4, borderRadius:100}, MainStyles.flexCenter]}>
            <Text style={{fontSize:10, color:'white', opacity:1, marginTop:0, fontFamily:GLOBAL.fonts.bold}}>
            EDITING
            </Text>
          </View>
        }
        <Pressable onPress={show_picker ? ()=>this.setState({show_picker:null}) : ()=>this.setState({show_picker:key})} style={[{height:54, paddingHorizontal:GLOBAL.padding*1.5, borderRadius:6, borderWidth:4, borderColor:value}, MainStyles.flexCenter]}>
          <Text style={{color:GLOBAL.style.color, fontSize:12, fontFamily:GLOBAL.fonts.bold, textTransform:'uppercase'}}>
          {title}
          </Text>
          <Text style={{fontSize:11, color:GLOBAL.style.color, opacity:0.5, marginTop:0, fontFamily:GLOBAL.fonts.regular}}>
          {value}
          </Text>
        </Pressable>
        </View>
        {
          // show_picker ?
          // <SketchPicker
          //   color={ value }
          //   onChangeComplete={(color)=>this.handleChangeComplete(key, color) }
          // />
          // : null
        }

      </View>
    )
  }

  renderButton = (text, onPress) => {
    return(
      <View style={[{marginTop:GLOBAL.padding*1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable
          onPress={onPress}
          style={[{borderColor:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, borderWidth:2, height:42, marginRight:0, paddingHorizontal:GLOBAL.padding*1.5, borderRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
            <Text style={{fontSize:12, color:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase'}}>
            {text}
            </Text>
          </Pressable>
        )}
        </Hoverable>
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View
      style={[{flex:1, width:'100%', padding:GLOBAL.padding}, MainStyles.flexCenter]}
      >
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
