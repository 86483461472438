/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  SectionList,
} from 'react-native';

//Modules

//Components
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'
import Player from '../../components/general/player.js'
import SectionHeader from '../../components/general/sectionheader.js'
import SectionItem from '../../components/general/sectionitem.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async (params) => {
    var { id, formats } = this.props

    this.setState({refreshing:true})

    if(!params){
      params = {}
    }

    params.id = id
    params.mode = 'match_info'
    params.submode = 'h2h&bios'

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}

    var { sections, players } = data

    await this.setState({sections, players, loading:false, refreshing:false})
  }

  renderContent = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        <SectionList
          ref={ref => (this.sectionListRef = ref)}
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          onScrollBeginDrag={this.props.onScroll}
          initialNumToRender={20}
          ListFooterComponent={this.renderPlayers}
        />
      </View>
    )
  }

  renderPlayers = () => {
    var { players } = this.state
    if(!players){return null}else{
      return(
        <View style={{width:'100%'}}>
        <SectionHeader title={'Players'}/>
        {players.map((item, index) => <Player index={index} data={item} allButtons/>)}
        </View>
      )
    }
  }

  renderItem = ({item, index}) => {
    // console.log(item)
    return <SectionItem item={item} index={index} mode='match_bios'/>
    // return null
  }

  renderSectionHeader = ({section}) => {
    var { title, type, players } = section
    return(
      <SectionHeader title={title} players={players} type={type} mode='match_bios'/>
    )
  }

  render = () => {
    var { loading } = this.state

    try{
      return (
        <View style={[{flex:1, width:'100%', minHeight:300}, MainStyles.flexStartCenter]}>
          {loading ? <Loading/> : this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
