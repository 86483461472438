/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  // Pressable,
  FlatList,
  RefreshControl
} from 'react-native';

import { Pressable, Hoverable } from "react-native-web-hover";

//
import LinearGradient from 'react-native-web-linear-gradient';
import DatePicker from 'react-datepicker';

//Components
import MatchList from '../../components/matches/matchlist.js'
import Icon from '../../components/general/icon.js'
import Tabs from '../../components/general/tabs.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

//Modules
var moment = require('moment-timezone');

var DATE_WIDTH = 100

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading:true,
      dates:[],
      mode:'all',
      tour:this.props.default_tour === 'all' ? '' : 'tour',
      date_index:4,
      countries:this.props.countries || [],
      missedUpdates:0,
      lastUpdate:0,
      // default_tour:this.props.default_tour,
    }
  }

  componentDidMount = async () => {
    this.full_setup()

    window.addEventListener('focus', this.handleFocus);
    window.addEventListener('blur', this.handleBlur);
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
    window.removeEventListener('focus', this.handleFocus);
    window.removeEventListener('blur', this.handleBlur);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }

  //
  handleFocus = () => {
    // console.log('focus')
  }

  handleBlur = () => {
    // console.log('blur')
  }

  handleVisibilityChange = () => {

    const time_compare = (this.state.refresh_time || (1 * 60 * 1000))

    // console.log('Visibility changed to: '+document.visibilityState, "Seconds to compare: "+time_compare/1000+" seconds")

    if(document.visibilityState === 'visible'){
      //Trigger an update if the data was updated more than a minute ago
      if((Date.now() - this.state.lastUpdate) > time_compare){
        // console.log('Viewing page and data is stale, asking for refresh')
        this.setup(null, null, null, true)
      }else{
        // console.log('Viewing page but data is fresh, wait for next update')
      }

    }
  }

  reset = () => {
    clearTimeout(this.refresh_data)
    this.setState({mode:'all'})
    this.setup(null, true, true)
  }

  refresh = () => {
    clearTimeout(this.refresh_data)
    this.setState({refreshing_all:true})
    this.full_setup()
  }

  full_setup = async () => {
    clearTimeout(this.refresh_data)
    await this.setupDates()
    this.setup()
  }

  //
  viewDatePicker = () => {
    this.setState({show_picker:true})
  }

  //
  switchDate = async (increase) => {
    var { date_index, dates } = this.state
    if(increase){
      date_index = date_index + 1
    }else{
      date_index = date_index - 1
    }

    var date = dates[date_index]
    await this.setState({date_index})
    this.changeDate(date)
  }

  changeDate = async (date) => {
    clearTimeout(this.refresh_data)
    var { mode } = this.state
    if(mode !== 'all'){
      mode = 'all'
    }
    this.setState({date, mode})
    this.setup({date:date.value}, false, true)
  }

  setupDates = async () => {
    var tz = await moment.tz.guess(true)

    var dates = [-4,-3,-2,-1,0,1,2,3,4]
    var date_index = 4

    await Promise.all(dates.map(async (date, index) => {
      dates[index] = {}
      if(date > 0){
        dates[index] = {
          header:moment().tz(tz).add(date, 'days').format('dddd'),
          title:moment().tz(tz).add(date, 'days').format('D'),
          subtitle:moment().tz(tz).add(date, 'days').format('MMM'),
          value:moment().tz(tz).add(date, 'days').format('YYYY-MM-DD'),
          selector_string:moment().tz(tz).add(date, 'days').format('ddd DD MMM')
        }
      }else if(date < 0){
        dates[index] = {
          header:moment().tz(tz).subtract(date*-1, 'days').format('dddd'),
          title:moment().tz(tz).subtract(date*-1, 'days').format('D'),
          subtitle:moment().tz(tz).subtract(date*-1, 'days').format('MMM'),
          value:moment().tz(tz).subtract(date*-1, 'days').format('YYYY-MM-DD'),
          selector_string:moment().tz(tz).add(date, 'days').format('ddd DD MMM')
        }
      }else if(date === 0){
        dates[index] = {
          header:'Today',
          title:moment().tz(tz).format('D'),
          subtitle:moment().tz(tz).format('MMM'),
          today:true,
          value:moment().tz(tz).format('YYYY-MM-DD'),
          selector_string:'Today'
        }
        // date_index = index
      }

      if(date === -1){
        dates[index].header = 'Yesterday'
        dates[index].selector_string = 'Yesterday'
      }
      if(date === 1){
        dates[index].header = 'Tomorrow'
        dates[index].selector_string = 'Tomorrow'
      }
    }))

    var today = moment().tz(tz).format('YYYY-MM-DD')
    // var date = dates[date_index]

    var date = dates[date_index]

    await this.setState({date, date_index, dates, today})
  }

  //
  changeTour = async (mode_data) => {
    clearTimeout(this.refresh_data)
    await this.setState({tour:mode_data.id})

    this.setup(null, null, true)
  }

  //
  changeFilter = () => {
    clearTimeout(this.refresh_data)
    var date_string = moment(this.state.date, 'YYYY-MM-DD').format('D MMM YYYY')
    this.props.navigation.navigate('DailyTournaments', {onFinish:this.applyFilter, date:this.state.date, date_string})
  }

  applyFilter = (filter) => {
    this.setState({filter})
    if(filter?.title){
      this.setState({
        header_filter:{
          title:filter.title,
          subtitle:this.state.header_filter?.subtitle || 'Matches'
        }
      })
    }
    this.setup({filter}, false, true)
  }

  //
  changeMode = async (mode_data) => {
    clearTimeout(this.refresh_data)

    var params = {}

    var mode = mode_data.id

    if(mode === 'live'){
      params.only_live = true
    }else if(mode === 'following'){
      params.only_favorites = true
    }else if(mode === 'completed'){
      params.only_completed = true
    }else if(mode === 'upcoming'){
      params.only_upcoming = true
    }

    await this.setState({mode})
    this.setup(params, false, true)
  }

  setup = async (params, reset, showRefreshing, disableScroll) => {

    clearTimeout(this.refresh_data)
    this.setState({lastUpdate:Date.now()})

    var { dates, mode } = this.state

    if(showRefreshing){
      await this.setState({sections:[], refreshing:true})
    }

    if(!params){
      params = {}
    }

    params.mode = 'matches_daily'
    params.theme = 'daily'

    if(!reset){
      if(!params.date){
        params.date = this.state.date ? (this.state.date.value) : null
      }

      if(!params.filter && this.state.tour){
        params.filter = {
          id:this.state.tour,
          type:'tour',
        }
      }

      if(this.state.countries.length > 0){
        params.countries = this.state.countries
      }

      if(mode === 'live'){
        params.only_live = true
      }else if(mode === 'following'){
        params.only_favorites = true
      }else if(mode === 'completed'){
        params.only_completed = true
      }else if(mode === 'upcoming'){
        params.only_upcoming = true
      }
    }

    // console.log(params.date)

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}
    var { sections, header_date, header_filter, date, refresh_time } = data
    this.refresh_time(refresh_time)

    await this.setState({sections, header_date, header_filter, loading:false, refreshing:false, refreshing_all:false})
  }

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    this.setState({refresh_time})
    console.log('Will refresh in '+refresh_time/1000+' seconds')
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{

        var update_data = true

        if(document.visibilityState === 'visible'){
          //Update, window is visible
          update_data = true
        }else if(this.state.missedUpdates > (1000 * 5 * 60 / refresh_time)){
          //Data is 5 mins old, update
          console.log('Missed enough updates, update the data')
          update_data = true
        }else{
          //Window blurred, don't update
          update_data = false
        }

        console.log('Refresh requested', 'Visbility: '+document.visibilityState, 'Missed updates: '+this.state.missedUpdates, "Updating: "+update_data)

        if(update_data){
          //Update
          this.setup(null, null, null, true)
          this.setState({missedUpdates:0})
        }else{
          //Missed
          //Increase the missed update count and wait for another refresh
          this.setState({missedUpdates:this.state.missedUpdates + 1})
          this.refresh_time(refresh_time)
        }


      }, refresh_time)
    }
  }

  onAppStateChange = async (appState) => {
    var app_backgrounded = appState === 'background'
    this.setState({appState, app_backgrounded})
    if(appState === 'active'){
      //Check if date today is the same
      var tz = await moment.tz.guess(true)
      var today = moment().tz(tz).format('YYYY-MM-DD')
      var today_changed = today !== this.state.today

      if(today_changed){
        //Today has changed, refresh the dates
        await this.setState({loading:true, refreshing:true, freshing_all:true})
        this.full_setup()
      }else{
        //Today still the same, restart the listener
        this.setup()
      }


    }
  }

  //Controls
  renderControls = () => {
    return(
      <View style={[{width:'100%', borderWidth:0, borderColor:GLOBAL.style.borderColor, borderRadius:GLOBAL.isMobile ? 0 : GLOBAL.borderRadius, marginBottom:0, overflow:'hidden', backgroundColor:GLOBAL.style.cardColor}, MainStyles.flexCenter]}>
        {
          this.renderControls_tour()
        }
        <View style={{width:'100%', height:1, backgroundColor:GLOBAL.style.borderColorSecondary}}/>
        {
          this.renderControls_mode()
        }

      </View>
    )
  }

  renderControls_mode = () => {
    var isToday = this.state?.date?.value !== this.state.today
    var modes = [
      // {id:'following', title:'Following'},
      {id:'all', title:'All'},
      {id:'live', title:'Live', hide:isToday},
      {id:'completed', title:'Completed', hide:isToday},
      {id:'upcoming', title:'Upcoming', hide:isToday},

    ]
    return !isToday ? <Tabs tabs={modes} onChange={this.changeMode} active_tab={this.state.mode} secondary/> : null
  }

  renderControls_tour = () => {
    var isToday = this.state.date !== this.state.today
    var modes = [
      {id:'', title:'All'},
      {id:'tour', title:'ATP & WTA'},
      {id:'atp', title:'ATP'},
      {id:'wta', title:'WTA'},
      {id:'challenger', title:'Challenger'},
      {id:'itf', title:'ITF'},
      // {id:'other', title:'Other'},

    ]
    return <Tabs tabs={modes} onChange={this.changeTour} active_tab={this.state.tour}/>
  }

  getItemLayout = (data, index) => ({
    length: (DATE_WIDTH + GLOBAL.padding/2),
    offset: (DATE_WIDTH + GLOBAL.padding/2) * index,
    index,
  });

  renderControls_dates = () => {

    var { header, header_temp, dates, date_index, date, show_picker } = this.state
    var size = 32

    var back = dates[date_index-1]
    var forward = dates[date_index+1]

    var disabled_opacity = 0.15

    return(
      <View style={[{width:'100%', borderBottomColor:GLOBAL.style.borderColor, borderBottomWidth:1}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable style={{padding:GLOBAL.padding}} onPress={!back ? null : ()=>this.switchDate(false)}>
            <View style={[{height:size, width:size, borderRadius:100, backgroundColor:hovered ? GLOBAL.style.hoverTab : 'rgba(0,0,0,0.04)', opacity:back ? 1 : disabled_opacity}, MainStyles.flexCenter]}>
              <Image style={{transform:[{rotate:'0deg'}], marginRight:2,height:size/2, width:size/2, resizeMode:'contain', tintColor:GLOBAL.style.color}} source={require('../../assets/icons/arrow.png')}/>
            </View>
          </Pressable>
        )}
        </Hoverable>


        <View
        // onPress={()=>this.setState({show_picker:!this.state.show_picker})}
        style={[{flex:1}, MainStyles.flexCenter]}>
          <Text style={{fontSize:14, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.bold}}>
          {date?.header}
          </Text>
          {
            // !date?.subtitle ? null :
            <Text style={{marginTop:2, fontSize:12, color:GLOBAL.style.color_secondary, fontFamily:GLOBAL.fonts.regular}}>
            {date?.title + " " + date?.subtitle}
            </Text>
          }
        </View>

        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable style={{padding:GLOBAL.padding}} onPress={!forward ? null : ()=>this.switchDate(true)}>
            <View style={[{height:size, width:size, borderRadius:100, backgroundColor:hovered ? GLOBAL.style.hoverTab : 'rgba(0,0,0,0.04)', opacity:forward ? 1 : disabled_opacity}, MainStyles.flexCenter]}>
              <Image style={{transform:[{rotate:'180deg'}], marginLeft:2, height:size/2, width:size/2, resizeMode:'contain', tintColor:GLOBAL.style.color}} source={require('../../assets/icons/arrow.png')}/>
            </View>
          </Pressable>
        )}
        </Hoverable>
        {
          // this.renderDatePicker()
        }
      </View>
    )
  }

  renderDatePicker = () => {
    var { show_picker, dates } = this.state
    if(!show_picker){return null}else{
      return(
        <View style={[{position:'absolute', width:'100%', bottom:0}, MainStyles.flexCenter]}>
          <View style={{padding:GLOBAL.padding, borderRadius:GLOBAL.borderRadius, backgroundColor:'white'}}>
          {dates.map((item, index) => this.renderDateList(item, index))}
          </View>
        </View>
        )
    }
  }

  renderDateList = (item, index) => {
    return(
      <Hoverable style={{}}>
      {({ hovered }) => (
        <Pressable>
          <Text>
          {item.selector_string}
          </Text>
        </Pressable>
      )}
      </Hoverable>

    )

  }

  renderDate = ({item, index}) => {
    var isActive = item.value === this.state.date

    var backgroundColor = isActive ? GLOBAL.colors.dark : 'rgba(0,0,0,0.04)'
    var color = isActive ? 'white' : GLOBAL.style.color

    return(
      <Pressable
      key={index}
      onPress={()=>this.changeDate(item.value)}
      // style={[{width:DATE_WIDTH, height:48, borderRadius:GLOBAL.borderRadius, borderWidth:0, borderColor:isActive ? GLOBAL.style.accent : GLOBAL.style.borderColor, marginRight:GLOBAL.padding/2, backgroundColor}, MainStyles.flexCenter]}
      style={({ hovered, focused, pressed }) => [
        {width:DATE_WIDTH, height:48, borderRadius:GLOBAL.borderRadius, borderWidth:0, borderColor:isActive ? GLOBAL.style.accent : GLOBAL.style.borderColor, marginRight:GLOBAL.padding/2, backgroundColor}, MainStyles.flexCenter,
        hovered && !isActive && {backgroundColor:GLOBAL.style.hoverTab},
        // focused && styles.buttonFocused,
        // pressed && styles.buttonPressed
      ]}
      >
        <Text style={{color, opacity:0.5, fontFamily:GLOBAL.fonts.regular, fontSize:12}}>
        {item?.header}
        </Text>
        <Text style={{color, fontFamily:GLOBAL.fonts.medium, fontSize:12}}>
        {item?.title} {item?.subtitle}
        </Text>
      </Pressable>
    )
  }

  onLayout = ({nativeEvent}) => {
    var height = nativeEvent.layout.height
    window.parent.postMessage({
      target:'tnnsWidgetHeight',
      value:height,
    },"*")
  }

  render = () => {
    try{
      return (
        <View style={[{flex:1, width:'100%', borderRadius:0}, MainStyles.flexStartCenter]}>
          <View onLayout={this.onLayout} style={[{width:'100%'}, MainStyles.flexCenter]}>
            <View style={{width:'100%', backgroundColor:'transparent'}}>
              {this.renderControls_dates()}
            </View>
            <MatchList
            theme={this.props.theme}
            onPress={this.props.onPressMatch}
            header={this.renderControls}
            data={this.state.sections}
            loading={this.state.loading}
            refreshing={this.state.refreshing}
            hideVideos={this.props.hideVideos}
            emptyStateButton={{
              title:'Reset Filters',
              onPress:this.reset
            }}
            refreshControl={
              <RefreshControl
                // size={'small'}
                tintColor={GLOBAL.style.color}
                refreshing={this.state.refreshing_all}
                onRefresh={this.refresh}
              />
            }
            />
          </View>
        </View>
      );
    }catch(e){
      return null
    }
  }

}
