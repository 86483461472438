/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Pressable,
  Linking,
  Image,
} from 'react-native';

//Modules

//Components
import Tabs from '../../components/general/tabs.js'

import Live from '../../components/live/live.js'
import Completed from '../../components/live/completed.js'
import Scheduled from '../../components/live/scheduled.js'


import Scores from '../../screens/matches/scores.js'
import MatchOverlay from '../../components/general/matchoverlaycard.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var isMobile = dimensions.width < 600

var accent = "#007BB2"
var accent_1 = "#36B9F2"
var color = "#121212"

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
      offset:0,
      style:{}
    }
  }

  async componentDidMount(){
    GLOBAL.external_host = this.props.theme

    var hideVideos = false
    var host = ''

    var style = {}

    if(this.props.theme === 'TennisHead'){
      accent = '#1393CC'
      accent_1 = "#36B9F2"
      color = "#fff"
      host = 'Tennishead'
    }else if(this.props.theme === 'Tweener'){
      accent_1 = '#121212'
      accent = "#232528"
      color="#fff"
      host = 'Tweener'
    }else if(this.props.theme === 'ltr'){
      this.setState({default_tour:'all'})
      // alert('bingo')
      accent_1 = '#02BFF3' //Blue
      accent = '#20AC34' //Green
      accent_1 = '#127E22'
      GLOBAL.fonts = {
        ultra:'Tahoma-Bold2',
        black:'Tahoma-Bold2',
        bold:'Tahoma-Bold2',
        medium:'Tahoma-Regular2',
        regular:'Tahoma-Regular2',
      }
      hideVideos = true
      host = 'LTR'
      GLOBAL.popUpMatches = true
    }else if(this.props.theme === 'usta'){
      accent = '#091F3F'
      accent_1 = '#408FDE'
      GLOBAL.fonts = {
        ultra:'Metropolis-Black',
        black:'Metropolis-Black',
        bold:'Metropolis-Bold',
        medium:'Metropolis-Medium',
        regular:'Metropolis-Regular',
      }
      host = 'USTA'
      GLOBAL.popUpMatches = true

      style.color_host = 'white'

      this.setState({scroll:true, countries:['USA'], default_tour:'all'})

    }else if(this.props.theme === 'buzz'){
      host = 'Tennisbuzz'
      accent = '#caf34c'
      accent_1 = '#121212'
      GLOBAL.style.active_tab_color = '#121212'

      GLOBAL.fonts = {
        ultra:'Roboto-Black',
        black:'Roboto-Black',
        bold:'Roboto-Bold',
        medium:'Roboto-Medium',
        regular:'Roboto-Regular',
      }

    }else if(this.props.theme === 'infinity'){
      accent = '#1EB3E1'
      accent_1 = '#119FCB'
      host = 'Tennis Infinity'
    }else if(this.props.theme === 'tennisconnected'){
      accent = '#457B3B'
      accent_1 = '#6BA161'
      host = 'Tennis Connected'
    }else if(this.props.theme === 'tennisnow'){
      accent = '#221E1F'
      accent_1 = '#61AF4A'
      // GLOBAL.style.active_tab_color = '#121212'
      host = 'Tennis Now'
      this.setState({scroll:true})
    }else if(this.props.theme === 'tennisexpress'){
      accent = '#121212'
      accent_1 = '#335A9E'
      // GLOBAL.style.active_tab_color = '#121212'
      host = 'Tennis Express'
      this.setState({scroll:true})
    }

    var has_style = false

    try{
      var route_style = this.props.style || ""
      if(route_style){
        has_style = true
        route_style = route_style.split("-").join('"')
        const style_obj = JSON.parse(route_style)
        if(style_obj['accent']){
          accent = style_obj['accent']
        }
        if(style_obj['accent_1']){
          accent_1 = style_obj['accent_1']
        }
        if(style_obj['color']){
          color = style_obj['color']
        }
      }
    }catch(e){
      console.log(e)
    }

    if(this.props.style){
      accent_1 = this.props.style.accent_1 || accent_1
      accent = this.props.style.accent || accent
      color = this.props.style.color || color
    }

    if(this.props.theme || has_style){
      //Set theme
      GLOBAL.darkMode = false
      GLOBAL.style = GLOBAL.style_light
      GLOBAL.externalMode = true
      GLOBAL.style.color_accent_1 = accent_1
      GLOBAL.color_accent_1 = accent_1

      GLOBAL.style.active_tab = accent
    }



    await this.setState({loading:false, hideVideos, style, host})

    if(GLOBAL.hideNavbar){
      GLOBAL.hideNavbar()
    }


  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  changeTab = (activeView) => {
    this.setState({activeView})
  }

  changeTabIndex = (activeIndex) => {
    this.setState({activeIndex})
  }

  viewOverlayMatch = () => {
    Functions.openURL("https://tnnslive.com/match/"+this.state.overlay)
  }

  overlay = async (data, nativeEvent) => {
    // // console.log(nativeEvent)
    // var { pageY } = nativeEvent
    // // alert(data.docId)
    //
    // if(pageY > 100){
    //   pageY = pageY - 100
    // }
    //
    // this.setState({overlay:data.docId, offset:pageY})

    window.open("https://tnnslive.com/match/"+data.docId, "hello", "width=375,height=800");

  }

  hide_overlay = () => {
    this.setState({overlay:null})
  }

  renderOverlay = () => {
    var { overlay, offset } = this.state
    // return <MatchOverlay id={overlay} showBranding/>

    if(overlay){
      return(
        <Pressable onPress={this.hide_overlay} style={[{width:'100%', height:'100%', position:'absolute', paddingHorizontal:GLOBAL.padding*2, backgroundColor:'rgba(0,0,0,0.85)'}, MainStyles.flexCenter]}>
          <View style={[{width:'100%', position:'absolute', top:offset}, MainStyles.flexCenter]}>
           <MatchOverlay id={overlay} showBranding hide_overlay={this.hide_overlay}/>
          </View>
        </Pressable>
      )
    }else{return null}
  }

  handleScroll = () => {
    console.log('Scrollllll')
  }


  renderMain = () => {
    var { activeView } = this.state

    var tabs = [
      {title:'Live'},
      {title:'Latest Results'},
      {title:'Up Next'},
    ]

    var render = <Scores hideVideos={this.state.hideVideos} theme={this.props.theme} onPressMatch={this.props.theme === 'ltr' ? this.overlay : null} countries={this.state.countries} default_tour={this.state.default_tour}/>

    var height = 64
    var icon_size = 36

    return(
      <View contentContainerStyle={[{width:'100%', backgroundColor:'white'}, MainStyles.flexStartCenter]} style={{flex:1, width:'100%', borderColor:GLOBAL.style.borderColor, borderWidth:1, backgroundColor:'white'}}>
        <View style={[MainStyles.flexCenter, {width:'100%'}]}>
          <Pressable onPress={()=>Linking.openURL("https://tnnslive.com")} style={[{width:'100%', backgroundColor:accent_1, paddingLeft:15, height}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>

            {
              <View style={[MainStyles.flexCenterStart, {}]}>
                <Text style={{fontSize:24, fontFamily:GLOBAL.fonts.black, color:this.state.style.color_host || 'white'}}>
                {this.state.host || 'Tennis'}
                </Text>
                <Text style={{fontSize:14, fontFamily:GLOBAL.fonts.medium, color:this.state.style.color_host || 'white', opacity:0.5, marginTop:0}}>
                Live Scores
                </Text>
              </View>
            }

            <View style={[{height:'100%'}, MainStyles.flexRow, MainStyles.flexCenter]}>

              {this.renderTriangle(height, accent, true)}
              <View style={[{height:'100%', paddingRight:15, backgroundColor:accent, zIndex:2, paddingLeft:10}, MainStyles.flexCenterStart]}>
                <Text style={{fontSize:12, fontFamily:GLOBAL.fonts.semibold, color:GLOBAL.style.active_tab_color || 'white', opacity:0.5, letterSpacing:1}}>
                POWERED BY
                </Text>
                <Text style={{fontSize:20, fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.active_tab_color || 'white', opacity:1, letterSpacing:0, marginTop:4}}>
                TNNSLIVE.COM
                </Text>
              </View>
            </View>

          </Pressable>
          <Tabs data={tabs} onTapTitle={this.changeTab} onTapIndex={this.changeTabIndex} forceIndex={this.state.forceIndex}/>
        </View>
        {render}
        {this.renderOverlay()}
      </View>
    )
  }

  renderTriangle = (height, backgroundColor, flip) => {
    return(
      <View
      style={{
        zIndex:1,
        marginLeft:-height/2,
        marginRight:-height/2,
        transform:[{scaleY:flip ? 1 : -1}],
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: height/2,
        borderRightWidth: height/2,
        borderBottomWidth: height,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: backgroundColor,
      }}
      />
    )
  }

  renderScroll = () => {
    return(
      <ScrollView style={{width:'100%', flex:1}} contentContainerStyle={{width:'100%'}}>
      {this.renderMain()}
      </ScrollView>
      )
  }

  render = () => {
    var { loading } = this.state
    try{
      return loading ? null : this.state.scroll ? this.renderScroll() : this.renderMain()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
