/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  Image,
  Pressable,
  ActivityIndicator,
  Dimensions
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";
import LinearGradient from 'react-native-web-linear-gradient';

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'

import Scores from './matches/scores.js'

import Match from '../components/matches/match.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
      active_tab:'tour',
      components:{},
    }
  }

  async componentDidMount(){
    GLOBAL.navigation = this.props.navigation

    // //Call API
    // var params = {
    //   mode:'dynamic_page',
    //   id:'web_home',
    // }
    //
    // var data_r = await Functions.tnnsAPI(params)
    // var { data } = data_r
    // var { components } = data
    //
    this.setState({loading:false})

  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  testLink = (mode) => {
    // alert(mode)

    if(mode === 'browser'){

    }else if(mode === 'safari'){
      window.open("safari://www.google.com", '_blank')
    }else if(mode === 'chrome'){
      window.open("googlechrome://vtagz.com")
    }else if(mode === 'tnnslive'){
      window.open("https://tnnslive.com", '_blank')
    }
  }

  changeTour = (data) => {
    this.setState({active_tab:data.id})
  }

  openTournament = (item) => {
    // item.id = "OIKcZx69FslB1Bvz9RqT"
    GLOBAL.navigation.push('Tournament', {id:item.id})
  }

  openVideo = (data) => {
    try{
      var url = data.onPress.data_player.url
      this.openLink(url)
      // window.open(url, '_self')
      // GLOBAL.navigation.push("Article", {url})
    }catch(e){
      console.log(e)
    }
  }

  openArticle = (data) => {
    try{
      var url = data.onPress.params.url
      // window.open(url, '_self')
      this.openLink(url)
      // GLOBAL.navigation.push("Article", {url})
    }catch(e){
      console.log(e)
    }
  }

  openLink = (url) => {
    console.log('Open link', url)
    window.open(url, 'blank')
    // GLOBAL.navigation.push("Article", {url})
  }

  viewAll = (screen) => {
    GLOBAL.navigation.push(screen)
  }

  //


  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Live Tennis Scores, Results, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var subtitle = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <SEOTitle title={title}/>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={subtitle}/>
      </Helmet>
      <Background navigation={this.props.navigation} page="Home"
      // title='Scores'
      // icon={require('../assets/icons/ball.png')}
      >
        {loading ? this.renderLoading() : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { activeView } = this.state

    return(
      <View style={[{width:'100%', backgroundColor:GLOBAL.style.cardColor, borderWidth:0, borderRadius:0, borderColor:GLOBAL.style.borderColor, marginTop:GLOBAL.padding*0}, MainStyles.flexStartCenter]}>
        <Scores/>
      </View>
    )
  }

  //

  renderFeaturedArticle = () => {
    var { components } = this.state
    var data = components.featured_news || []
    var article = data[0] || {}


    var image = article.image
    var header = "Featured news"
    var title = article.title

    return(
      <Pressable onPress={()=>this.openArticle(article)} style={[{width:'100%', backgroundColor:GLOBAL.style.featured}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', height:'100%', maxWidth:GLOBAL.maxWidth, aspectRatio:GLOBAL.isMobile ? 1.5 : 2, backgroundColor:'transparent'}, MainStyles.flexCenter]}>
          <Image style={{width:'100%', height:'100%'}} source={{uri:image}}/>
            <LinearGradient
            style={[{height:'100%', width:'100%', position:'absolute', bottom:0}, MainStyles.flexCenter]}
            colors={['rgba(0,0,0,0.67)', 'rgba(0,0,0,0)']}
            start={{x:0,y:1}}
            end={{x:0,y:0.5}}
            />
            <View style={[{position:'absolute', bottom:0, width:'100%', padding:GLOBAL.isMobile ? GLOBAL.padding : GLOBAL.padding*2}, MainStyles.flexCenterStart]}>
              <View style={[{flex:1, maxWidth:'80%'}, MainStyles.flexCenterStart]}>
                <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:14, color:'white', flexWrap:'wrap', opacity:0.5, textTransform:'uppercase'}} numberOfLines={3}>
                {header}
                </Text>
                <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:28, color:'white', flexWrap:'wrap', textTransform:'uppercase', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 10, textShadowColor: 'rgba(0,0,0,0.5)'}} numberOfLines={8}>
                {title}
                </Text>
              </View>
              {
                // <Pressable style={[{height:34, paddingHorizontal:GLOBAL.padding, borderRadius:100, backgroundColor:'rgba(0,0,0,0.33)', marginLeft:GLOBAL.padding * 0, marginTop:GLOBAL.padding}, MainStyles.flexCenter]}>
                //   <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:14, color:'white', textTransform:'uppercase'}}>
                //   Read More
                //   </Text>
                // </Pressable>
              }
            </View>
        </View>
      </Pressable>
    )
  }

  renderPolls = () => {
    var { components } = this.state
    var data = components.polls || []

    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Daily Polls", false)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        {data.map((item, index) => this.renderPoll(item, index))}
        </View>
      </View>
    )
  }

  renderPoll = (item, index) => {
    return(
      <Pressable style={{height:250, aspectRatio:1.5, borderRadius:GLOBAL.borderRadius, backgroundColor:GLOBAL.style.cardColor, marginRight:GLOBAL.padding, marginBottom:GLOBAL.padding}}>
      </Pressable>
    )
  }

  renderMatches = () => {
    var { components } = this.state
    var data = components.matches || []
    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Popular Matches", 'Scores')}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {data.map((item, index) => this.renderMatch(item, index))}
        </View>
      </View>
    )
  }

  renderMatch = (item, index) => {
    if(item.id === 'match'){
      return this.renderCard(<Match data={item.data} index={0}/>, true)
    }else{return null}
  }

  renderLinks = () => {
    var data = [
      {title:'Streams', screen:'Streams'},
      {title:'Scores', screen:'Scores'},
      {title:'Rankings', screen:'Rankings'},
      // {title:'Fantasy', screen:'Fantasy'},
    ]

    if(GLOBAL.isMobile){
      data = [
        {title:'Streams', screen:'Streams'},
        {title:'Scores', screen:'Scores'},
        {title:'Rankings', screen:'Rankings'},
      ]
    }

    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Quick Links", false)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        {data.map((item, index) => this.renderQuickLink(item, index, data.length))}
        </View>
      </View>
    )
  }

  renderQuickLink = (item, index, length) => {
    var flex = 1/length
    return(
      <View style={{flex:flex, paddingRight:GLOBAL.padding}}>
      <Pressable onPress={()=>this.openScreen(item.screen)} style={[{width:'100%',height:42, borderRadius:GLOBAL.borderRadius, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:14, color:GLOBAL.style.color, textTransform:'uppercase'}}>
        {item.title}
        </Text>
      </Pressable>
      </View>
    )
  }

  openScreen = (screen) => {
    GLOBAL.navigation.push(screen)
  }

  renderTournaments = () => {
    var { components } = this.state
    var data_all = components.tournaments || {}
    var data = data_all[this.state.active_tab] || []

    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Tournaments", true)}
        {this.renderTabs()}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          {data.map((item, index) => this.renderTournament(item, index))}
        </View>
      </View>
    )
  }

  renderTournament = (item, index) => {
    return this.renderCard(this.renderTournamentItem(item, index))
  }

  renderTournamentItem = (item, index) => {
    var backgroundColor = 'transparent'

    var size = 48
    var height = 98

    var logo_width = 72
    if(logo_width > 100){logo_width = 100}

    return(
      <Pressable onPress={()=>this.openTournament(item)} style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>

        <View style={[{flex:1}, MainStyles.flexCenterStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, fontSize:14}} numberOfLines={1}>
          {item.title}
          </Text>

          <Text style={{marginTop:GLOBAL.padding / 2, fontSize:12, color:GLOBAL.style.color, opacity:0.67, fontFamily:GLOBAL.fonts.regular}}>
          {item.subtitle_1}
          </Text>

          {
            !item.subtitle_2 ? null :
            <Text style={{marginTop:GLOBAL.padding / 4, fontSize:12, color:GLOBAL.style.color, opacity:0.67, fontFamily:GLOBAL.fonts.regular}}>
            {item.subtitle_2}
            </Text>
          }
        </View>

        {
          item?.logos?.length === 0 ? null :
          <View style={[{marginLeft:GLOBAL.padding, width:(logo_width+GLOBAL.padding), minHeight:logo_width, borderLeftWidth:1, borderLeftColor:GLOBAL.style.borderColor}, MainStyles.flexCenterEnd]}>
            {item?.logos?.map((item2, index2) => this.renderItem_logo(item2, index2, logo_width))}
          </View>
        }

      </Pressable>
    )
  }

  renderItem_logo = (item, index, logo_size) => {
    var marginTop = index > 0 ? GLOBAL.padding / 4 : 0
    var scale = item.scale || 1
    var size = logo_size * scale
    return(
      <Image
      key={index}
      style={[{height:item.height || size*(1/item.aspectRatio || 1), width:item.width || size, maxHeight:(size*0.35), marginTop, resizeMode:'contain'}]}
      source={{uri:item.url}}
      />
    )
  }

  renderCard = (content, disablePadding) => {
    var width = '33%'
    var padding = GLOBAL.padding
    var backgroundColor = GLOBAL.style.cardColor
    var borderWidth = 0
    var marginVertical = GLOBAL.padding/2

    if(GLOBAL.isMobile){
      width = '100%'
      padding = 0
      backgroundColor = 'transparent'
      borderWidth = 1
      marginVertical = GLOBAL.padding/2
    }
    return(
      <View style={{width, paddingRight:padding, marginBottom:padding, marginBottom:marginVertical}}>
        <View style={{width:'100%', padding:disablePadding ? 0 : GLOBAL.padding, paddingHorizontal:disablePadding ? 0 : padding, borderRadius:GLOBAL.borderRadius, backgroundColor, borderColor:GLOBAL.style.borderColor, borderWidth:1, overflow:'hidden'}}>
          {content}
        </View>
      </View>
    )
  }

  renderTabs = (tabs) => {
    var tabs = [
      {title:'ATP & WTA', id:'tour'},
      {title:'Challenger', id:'challenger'},
      {title:GLOBAL.isMobile ? "ITF" : 'ITF World Tour', id:'itf'},
      // {title:'ITF World Tour', id:'itf'},
    ]

    return(
      <View style={[{width:'100%', flexWrap:'wrap', marginTop:GLOBAL.padding/2, marginBottom:GLOBAL.padding}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
      {tabs.map((item, index) => this.renderTab(item, index))}
      </View>
    )
  }

  renderTab = (data, index) => {
    var isActive = this.state.active_tab === data.id
    return(
      <Pressable onPress={()=>this.changeTour(data)} style={[{paddingRight:GLOBAL.padding*2, paddingVertical:GLOBAL.padding / 2}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:16, color:GLOBAL.style.color, opacity:isActive ? 1 : 0.5, textTransform:'uppercase'}}>
        {data.title}
        </Text>
      </Pressable>
    )
  }

  renderHighlights = () => {
    var { components } = this.state
    var data = components.highlights || []
    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Highlights", false)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          {data.map((item, index) => this.renderHighlight(item, index))}
        </View>
      </View>
    )
  }

  renderNews = () => {
    var { components } = this.state
    var data = components.news || []
    return(
      <View style={{width:'100%'}}>
        {this.renderSectionTitle("Latest News", false)}
        <View style={[{width:'100%', flexWrap:'wrap'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          {data.map((item, index) => this.renderNewsItem(item, index))}
        </View>

      </View>
    )
  }

  renderPoll = (data) => {
    // var { choices, totalVotes, title, hasBeenVoted, votedChoiceById } = data.data
    // return <Poll data={data.data}/>
    return null
  }

  renderHighlight = (data, paddingRight) => {
      var borderRadius = GLOBAL.padding / 4
      return this.renderCard(
        <Pressable onPress={()=>this.openVideo(data)} style={[{width:'100%'}]}>
          <View style={[{width:'100%', borderRadius, overflow:'hidden'}, MainStyles.flexCenter]}>
            <Image
            style={[{width:'100%', aspectRatio:1920/1080}, MainStyles.flexCenter]}
            source={{uri:data?.image}}
            />
            <View style={[{flex:1, width:'100%', padding:GLOBAL.padding / 2}]}>
              <Text style={{fontFamily:GLOBAL.fonts.medium, fontSize:12, color:GLOBAL.style.color, textAlign:'left', flexWrap:'wrap'}} numberOfLines={2}>
              {data.title}
              </Text>
              <View style={[{flex:1, marginTop:GLOBAL.padding / 2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
                <Image
                style={[{width:14, aspectRatio:1, borderRadius:100, marginRight:GLOBAL.padding / 2, backgroundColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}
                source={{uri:data?.metadata?.image}}
                />
                <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:11, color:GLOBAL.style.color_secondary, textAlign:'left', flexWrap:'wrap'}} numberOfLines={1}>
                {data?.metadata?.text}
                </Text>
              </View>
            </View>
          </View>
        </Pressable>
      , true)
    }

  renderNewsItem = (data) => {
      // console.log(data.onPress?.params?.showComments?.onPress)
      var width_small = dimensions.width / 4
      var aspectRatio = 130/84
      var borderRadius = GLOBAL.padding / 4

      var comments = data.comments || 0

      data.featured = true

      var text_render =
      <View style={[{flex:1, width:'100%'}]}>
        <Text style={{fontFamily:GLOBAL.fonts.medium, fontSize:14, color:GLOBAL.style.color, textAlign:'left', flexWrap:'wrap'}} numberOfLines={3}>
        {data.title}
        </Text>
        <View style={[{flex:1, marginTop:GLOBAL.padding / 2}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            <Image
            style={[{width:14, aspectRatio:1, borderRadius:100, marginRight:GLOBAL.padding / 2, backgroundColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}
            source={{uri:data?.metadata?.image}}
            />
            <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:11, color:GLOBAL.style.color_secondary, textAlign:'left', flexWrap:'wrap', flexShrink:1}} numberOfLines={1}>
            {data?.metadata?.text}
            </Text>
          </View>

          {
            !comments ? null :
            <View style={[{opacity:0.5}, MainStyles.flexRow, MainStyles.flexCenter]}>
              <Text style={{color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.medium, fontSize:11, marginRight:GLOBAL.padding / 4}}>
              {comments}
              </Text>
            </View>
          }

        </View>
      </View>

      var render =
      <Pressable onPress={()=>this.openArticle(data)} style={[{width:'100%'}, MainStyles.flexStartStart]}>
        {
          !data.featured ?
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>
            <Image
            style={[{width:width_small, aspectRatio, borderRadius:0, backgroundColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}
            source={{uri:data?.image}}
            />
            <View style={[{flex:1, paddingLeft:GLOBAL.padding}, MainStyles.flexStartStart]}>
              {text_render}
            </View>
          </View>
          :
          <View style={[{width:'100%'}, MainStyles.flexCenter]}>
            <Image
            style={[{width:'100%', aspectRatio, borderRadius:0, marginBottom:GLOBAL.padding, backgroundColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}
            source={{uri:data?.image}}
            />
            <View style={{width:'100%', padding:GLOBAL.padding, paddingTop:0}}>
            {text_render}
            </View>
          </View>
        }
      </Pressable>

      return this.renderCard(render, true)
    }


  renderSectionTitle = (title, view_all) => {
    return(
      <View style={[{width:'100%', marginTop:GLOBAL.padding * 3, marginBottom:GLOBAL.padding*1}, MainStyles.flexRow, MainStyles.flexBetweenStart]}>
        <View style={[{flex:1}, MainStyles.flexCenterStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.ultra, fontSize:24, color:GLOBAL.style.color, textAlign:'left', textTransform:'uppercase'}}>
          {title}
          </Text>
          <View style={{height:4, width:42, backgroundColor:GLOBAL.style.color, marginTop:GLOBAL.padding/2}}/>
        </View>
        {
          !view_all || view_all ? null :
          <Pressable onPress={()=>this.viewAll(view_all)} style={[{height:30, paddingHorizontal:GLOBAL.padding, borderRadius:100, backgroundColor:GLOBAL.style.color, marginLeft:GLOBAL.padding * 2}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:14, color:GLOBAL.style.backgroundColor, textTransform:'uppercase'}}>
            View All
            </Text>
          </Pressable>
        }
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View
      style={[{flex:1, width:'100%', padding:GLOBAL.padding}, MainStyles.flexCenter]}
      >
        <Image
        style={{width:GLOBAL.displayMode === 'mobile' ? 120 : 120, aspectRatio:76/43, resizeMode:'contain', marginBottom:GLOBAL.padding}}
        source={require('../images/logo-dark.webp')}
        />
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
