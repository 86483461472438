/* @flow */
import React, { PureComponent } from 'react';
import {
  View,
  ActivityIndicator,
  Text,
  Platform,
  Dimensions,
  Image,
  Linking,
} from 'react-native';

import 'react-native-gesture-handler';
import { NavigationContainer, useRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LinearGradient from 'react-native-web-linear-gradient';
import { Helmet } from "react-helmet";

import MainStyles from './helpers/mainstyles.js'
import Functions from './helpers/functions.js'
import GLOBAL from './helpers/global.js'


import Auth from './screens/auth.js'

import Home from './screens/home.js'
import HomeWeb from './screens/home_web.js'
import Search from './screens/search.js'
import News from './screens/news.js'
import Videos from './screens/videos.js'
import Podcasts from './screens/podcasts.js'
import Widget from './screens/widgets.js'
import WidgetTest from './screens/widget_test.js'

import Download from './screens/download.js'
import Article from './screens/article.js'
import Stream from './screens/stream.js'
import Streams from './screens/streams.js'
import HowToStream from './screens/howtostream.js'
import Landing from './screens/landing.js'
import Support from './screens/support.js'
import Feedback from './screens/feedback.js'
import Widgets from './screens/widget.js'
import WidgetBuilder from './screens/widgetbuilder.js'
import FantasyRules from './screens/fantasyrules.js'
import FantasyBracket from './screens/fantasybracket.js'


import Scores from './screens/matches.js'
import Match from './screens/match.js'
import H2H from './screens/h2h.js'
import Tournament from './screens/tournament.js'
import Tournaments from './screens/tournaments.js'
import Player from './screens/player.js'
import Fantasy from './screens/fantasy.js'
import Settings from './screens/settings.js'
import Rankings from './screens/rankings.js'
import Ranking from './screens/ranking.js'

import VideoOverlay from './components/general/videooverlay.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

import { initAmplitudeInstance, logAmplitudeEvent } from './AmplitudeUtils';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
  authDomain: "tennis-lwts.firebaseapp.com",
  projectId: "tennis-lwts",
  storageBucket: "tennis-lwts.appspot.com",
  messagingSenderId: "414782898270",
  appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
  // measurementId: "G-D3SD6S9JJW"
  measurementId:"G-WVPSD2WN29",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');
// ReactGA.initialize("G-WVPSD2WN29")

const Stack = createStackNavigator();

var isMobileDevice = Dimensions.get('window').width < 900
var isTablet = Dimensions.get('window').width < 1280

GLOBAL.displayMode = isMobileDevice ? 'mobile' : (isTablet ? 'tablet' : 'web')
// GLOBAL.darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
// GLOBAL.style = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? GLOBAL.style_dark : GLOBAL.style_light
GLOBAL.style = GLOBAL.style_light
// GLOBAL.style = GLOBAL.style_dark

// Gets the current screen from navigation state

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
    }
  }

  async componentDidMount(){

    Functions.googleAnalytics('opened_tnns_web')

    GLOBAL.hideNavbar = this.hideNavbar

    GLOBAL.isMobile = isMobileDevice

    // await initAmplitudeInstance().then(() => {
    //   logAmplitudeEvent('Web - Start Session', {
    //     platform: Platform.OS,
    //   });
    // });

    // var isMobile = Dimensions.get('window').width < 900
    // var isTablet = Dimensions.get('window').width < 1200
    //
    // GLOBAL.displayMode = isMobile ? 'mobile' : (isTablet ? 'tablet' : 'web')
    GLOBAL.refresh = this.refresh

    GLOBAL.showVideo = this.showVideo

    await this.setState({isMobile:isMobileDevice})
    //Get user ip address
    const publicIp = require('public-ip');
    var ip = await publicIp.v4()
    GLOBAL.ip = ip

    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
      // dark mode
      // GLOBAL.darkMode = true
      // GLOBAL.style = GLOBAL.style_dark
    }

    //Log user into via Auth
    setTimeout(()=>{
      this.setState({loading:false})
    }, 1500)
  }

  openStore = () => {
    if(isMobile && isIOS){
      //Send user to the app store
      Linking.openURL("https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")
    }else if(isMobile && isAndroid){
      console.log('Sending user to Play Store')
      //Send user to the app store
      Functions.openURL("https://play.google.com/store/apps/details?id=com.tennisrn")
    }
  }

  async componentDidUpdate(prevProps){

  }

  hideNavbar = () => {
    this.setState({hideNavbar:true})
  }

  refresh = async (link) => {
    await this.setState({loading:true})
    await this.setState({loading:false})

    //Navigate to the link
  }

  async componentWillUnmount(){

  }

  showVideo = (video) => {
    console.log('Video change requested', video)
    this.setState({video})
  }

  renderOverlay = () => {
    var { video } = this.state
    return <VideoOverlay id={video} clearVideo={()=>this.setState({video:null})}/>
  }


  render = () => {
    var { loading } = this.state

  //   const ref = React.useRef();
  // const routeNameRef = React.useRef();

    const linking = {
      prefixes: ['https://downloadtennis.com', 'https://www.downloadtennis.com', "https://tnnsweb.web.app/", 'tennisrn://', "http://localhost:3001", "https://tnnslive.com", "https://www.tnnslive.com"],
      config: {
        screens: {
          // Auth:'',
          Home: '',
          Scores:'scores',
          // Matches:'matches',
          Match:'match/:id',
          H2H:'h2h/:nameString/:id1/:id2',
          Player:'player/:id',
          Article:'article/:id',
          Tournament:'tournament/:id',
          Tournaments:'tournaments',
          News:'news',
          Videos:'videos',
          Podcasts:'podcasts',
          Search:'search',
          // Fantasy:'fantasy',
          // Settings:'settings',
          Rankings:'rankings',
          Ranking:'ranking/:tour/:format/:type',
          Download: 'download',
          Streams:'streams',
          HowToStream:'howtostream',
          Stream: 'stream/:category/:id',
          Support: 'support',
          Feedback: 'feedback',
          Widgets: 'widgets/:id',
          WidgetBuilder: 'widgetbuilder/:id',
          Widget:'widget',
          WidgetTest:'widgettest',
          FantasyRules:'fantasyrules',
          FantasyBracket:'bracket/:mode/:tournamentId/:userId',
        }
      },
    }

    return(
      <View style={{width:'100%', flex:1, marginTop:0}}>
        <NavigationContainer
        linking={linking}
        onStateChange={state => {
          var routes = state.routes || []
          var screen = routes.length>0 ? routes[routes.length -1] : null
          if(screen){
            // console.log(screen.name, 'Routes')
            if(screen.name){
              // alert(screen.name)
              // ReactGA.pageview('Web - '+screen.name);
              logEvent(analytics, 'screen_view', {
                firebase_screen: screen.name,
                // firebase_screen_class: screenClass
              });
            }
          }
          // const previousRouteName = routeNameRef.current;
          // const currentRouteName = getActiveRouteName(state);
          //
          // const nextRouteName = getPrevRouteName(state)
          // GLOBAL.nextRouteName = nextRouteName
          //
          // if (previousRouteName !== currentRouteName) {
          //   // var screen_tag = ("Screen View - "+currentRouteName)
          //   // crashlytics().log('User viewed screen ' + currentRouteName)
          //   // Functions.amplitudeEvent(screen_tag)
          //   //
          //   // analytics().logScreenView({
          //   //   screen_name: currentRouteName,
          //   //   screen_class: currentRouteName,
          //   // });
          //   // // analytics().logEvent(currentRouteName)
          //   // // analytics().setCurrentScreen(currentRouteName, currentRouteName);
          //   GLOBAL.currentScreen = currentRouteName
          // }
          //
          // // Save the current route name for later comparision
          // routeNameRef.current = currentRouteName;
        }}
        >
          {
            // loading ? null :
            <Stack.Navigator
            headerMode='none' transparentCard={true} initialRouteName="Home"
            >
              <Stack.Screen name="Home" component={HomeWeb}/>
              <Stack.Screen name="Scores" component={HomeWeb}/>
              <Stack.Screen name="Rankings" component={Rankings}/>

              <Stack.Screen name="Search" component={Search}/>
              <Stack.Screen name="News" component={News}/>
              <Stack.Screen name="Videos" component={Videos}/>
              <Stack.Screen name="Podcasts" component={Podcasts}/>

              <Stack.Screen name="Article" component={Article}/>

              <Stack.Screen name="Player" component={Player}/>
              <Stack.Screen name="Match" component={Match}/>
              <Stack.Screen name="Tournament" component={Tournament}/>
              <Stack.Screen name="Tournaments" component={Tournaments}/>
              <Stack.Screen name="Ranking" component={Ranking}/>
              <Stack.Screen name="H2H" component={H2H}/>


              <Stack.Screen name="Download" component={Download} />

              <Stack.Screen name="HowToStream" component={HowToStream} />
              <Stack.Screen name="Streams" component={Streams} />
              <Stack.Screen name="Stream" component={Stream} />

              <Stack.Screen name="Widgets" component={Widgets} />
              <Stack.Screen name="Widget" component={Widget} />
              <Stack.Screen name="WidgetBuilder" component={WidgetBuilder} />
              <Stack.Screen name="WidgetTest" component={WidgetTest} />

              <Stack.Screen name="Support" component={Support} />
              <Stack.Screen name="Feedback" component={Feedback} />
              <Stack.Screen name="FantasyRules" component={FantasyRules} />
              <Stack.Screen name="FantasyBracket" component={FantasyBracket} />
            </Stack.Navigator>
          }

          {this.renderOverlay()}

        </NavigationContainer>
      </View>
    )
  }

}
