/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  // Pressable,
} from 'react-native';

//Modules
import { Pressable } from "react-native-web-hover";

//Components


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      tabs:this.props.tabs || [],
      active_tab:this.props.active_tab,
    }
  }

  async componentDidMount(){
  }

  async componentDidUpdate(prevProps){
    if(prevProps.tabs !== this.props.tabs){
      this.setState({tabs:this.props.tabs || []})
    }
    if(prevProps.force_tab !== this.props.force_tab){
      this.setState({active_tab:this.props.force_tab || null})
    }
  }

  async componentWillUnmount(){

  }

  changeTab = (item) => {
    if(this.props.onChange){
      this.props.onChange(item)
    }
    this.setState({active_tab:item.id})
  }

  renderTab = ({item, index}) => {
    var { active_tab } = this.state
    var isActive = item.id === active_tab
    var color = isActive ? GLOBAL.style.active_tab_color || 'white' : GLOBAL.style.color
    var backgroundColor = isActive ? GLOBAL.style.active_tab || GLOBAL.colors.button : 'rgba(0,0,0,0.04)'

    if(this.props.secondary && isActive){
      backgroundColor = '#4A4E53'
      color = 'white'
    }

    // if(item.hide){return null}else{
    //   return(
    //     <Pressable key={index} onPress={()=>this.changeTab(item)} style={[{paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/2, height:48, borderBottomColor:isActive ? GLOBAL.style.color : 'transparent', borderBottomWidth:0}, MainStyles.flexCenter]}>
    //       <Text style={{color, fontFamily:GLOBAL.fonts.bold, fontSize:14, marginTop:4, textTransform:'uppercase'}}>
    //       {item?.title || item?.name}
    //       </Text>
    //       <View style={[{position:'absolute', width:'100%', height:2, opacity:isActive ? 1 : 0, bottom:0, paddingHorizontal:(GLOBAL.padding-4)}, MainStyles.flexCenter]}>
    //         <View style={{width:'100%', height:2, backgroundColor:GLOBAL.style.accent}}/>
    //       </View>
    //     </Pressable>
    //   )
    // }

    if(item.hide){return null}else{
      return(
        <Pressable
        key={index}
        onPress={()=>this.changeTab(item)}
        style={({ hovered, focused, pressed }) => [
          {paddingHorizontal:GLOBAL.padding, marginLeft:GLOBAL.padding/2, borderRadius:GLOBAL.borderRadius, height:30, backgroundColor, borderBottomWidth:0}, MainStyles.flexCenter,
          hovered && !isActive && {backgroundColor:GLOBAL.style.hoverTab},
          // focused && styles.buttonFocused,
          // pressed && styles.buttonPressed
        ]}
        // style={[{paddingHorizontal:GLOBAL.padding, marginLeft:GLOBAL.padding/2, borderRadius:GLOBAL.borderRadius, height:30, backgroundColor, borderBottomWidth:0}, MainStyles.flexCenter]}
        >
          <Text style={{color, fontFamily:GLOBAL.fonts.bold, fontSize:12, marginTop:0, textTransform:'uppercase'}}>
          {item?.title || item?.name}
          </Text>
        </Pressable>
      )
    }
  }

  render = () => {
    var { tabs } = this.state
    try{
      if(tabs.length > 1){
        return(
          <FlatList
          style={{width:'100%', borderBottomColor:GLOBAL.style.borderColor, backgroundColor:'transparent', borderBottomWidth:0, marginVertical:GLOBAL.padding/2}}
          contentContainerStyle={{paddingHorizontal:0}}
          data={tabs}
          renderItem={this.renderTab}
          horizontal
          showsHorizontalScrollIndicator={false}
          />
        )
      }else{return null}
    }catch(e){
      return null
    }
  }

}
