/* @flow */

import React, { PureComponent } from 'react';
import {
  View, ImageBackground, ActivityIndicator, Text, ScrollView, SectionList, Dimensions, Picker, Animated, FlatList, TextInput, Image, Pressable, Modal
} from 'react-native';

//Modules
import LinearGradient from 'react-native-web-linear-gradient';
import { Hoverable } from "react-native-web-hover";

import NavBar from '../navigation/navbar.js'
import Footer from '../navigation/footer.js'
import AppCTA from './appcta.js'
import Video from '../media/video.js'

import Button from '../../components/general/button.js'
import Scores from '../../components/widgets/scores.js'
import Videos from '../../components/widgets/videos.js'

import Tournament from '../tournament/tournament.js'
import Player from '../players/player.js'
import Tournaments from '../../screens/matches/tournaments.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'
import Functions from '../../helpers/functions.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

var column_width = 300

var titleStyle = {}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      tournaments:GLOBAL?.tournaments || [],
      activeView:GLOBAL.activeViewTournaments || 'All',
      videos:GLOBAL.videos || [],
      showSearch:false,
      tournaments_all:[],
      players_all:[],
      matches_all:[],
      hideColumn:this.props.hideColumn,

      tournaments:GLOBAL.tournaments || [],
      news:GLOBAL.news || [],
    }
  }

  async componentDidMount(){
    GLOBAL.navigation = this.props.navigation
    GLOBAL.lockScroll = this.lockScroll

    //Get the tournaments and news data
    if(!GLOBAL.isMobile){
      this.getData()
    }

    titleStyle = {fontSize:12, color:GLOBAL.style.color_font, fontFamily:GLOBAL.fonts.bold, letterSpacing:1, textTransform:'uppercase', marginBottom:GLOBAL.padding / 2, paddingHorizontal:GLOBAL.padding/4, opacity:1}
  }

  async componentDidUpdate(prevProps){
    if(prevProps.hideColumn !== this.props.hideColumn){
      this.setState({hideColumn:this.props.hideColumn})
    }
  }

  getData = async () => {
    //Check if we already have the tournaments and news locally stored and fresh (5 mins)

    //If not, Call the API
    var params = {
      mode:'web_side_panels',
    }

    var data_r = await Functions.tnnsAPI(params)
    if(data_r){
      var { data } = data_r
      var { news, tournaments, rankings, top_10 } = data
      GLOBAL.tournaments = tournaments
      GLOBAL.news = news
      GLOBAL.rankings = rankings
      GLOBAL.top_10 = top_10
      this.setState({loading:false, news, tournaments, rankings, top_10})
    }
  }

  download = () => {
    this.setState({show_modal:true})
  }

  onLayout = ({nativeEvent}) => {
    this.setState({minHeight:nativeEvent.layout.height})
  }

  getTournaments = async () => {
    var tournaments = []

    if(GLOBAL?.tournaments && GLOBAL?.tournaments?.length > 0){
      tournaments = GLOBAL?.tournaments
    }else{
      //Get tournaments from API
      var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTournaments?=active?=Tour"
      var tournamentsRaw = await Functions.firestoreAPI(url)

      if(tournamentsRaw){
        tournaments = tournamentsRaw
      }
      GLOBAL.tournaments = tournaments
    }

    tournaments = tournaments || []

    this.setState({tournaments})

    //Set all tournaments into object for GLOBAL
    var tournamentsObj = {}
    await Promise.all(tournaments.map(async tournament => {
      tournamentsObj[tournament.id] = tournament
    }))
    GLOBAL.tournamentsObj = tournamentsObj
  }

  getVideos = async () => {
    var videos = []

    if(GLOBAL?.videos && GLOBAL?.videos?.length > 0){
      videos = GLOBAL?.videos
    }else{
      //Get tournaments from API
      var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getMedia?=videos"
      var videos_raw = await Functions.firestoreAPI(url)

      if(videos_raw){
        videos = videos_raw
      }
      GLOBAL.videos = videos
    }
    this.setState({videos, loading:false})
  }

  changeView = (activeView) => {
    this.setState({activeView})
    GLOBAL.activeViewTournaments = activeView
  }

  lockScroll = (lockScroll) => {
    this.setState({lockScroll})
  }

  getApp = () => {
    if(isMobile && isIOS){
      //Send user to the app store
      Functions.openURL("https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")
    }else if(isMobile && isAndroid){
      //Send user to the app store
      Functions.openURL("https://play.google.com/store/apps/details?id=com.tennisrn")
    }
  }

  openPage = (page) => {
    GLOBAL.page = page
    this.props.navigation.push(page)
  }


  //
  renderLoading = () => {
    return(
      <View style={[{width:'100%', flex:1}, MainStyles.flexCenter]}>
      <ActivityIndicator size='small' color={GLOBAL.style.color_accent_1}/>
      </View>
    )
  }

  renderScores = () => {
    var show = GLOBAL.displayMode !== 'mobile'
    if(show){
      return(
        <View style={[{width:'100%', borderRadius:0, backgroundColor:GLOBAL.style.backgroundColor, marginBottom:GLOBAL.padding_card/0, borderBottomColor:GLOBAL.darkMode ? 'rgba(255,255,255,0.08)' : GLOBAL.style.borderColor, borderBottomWidth:0}, MainStyles.flexCenter]}>
          {<Scores/>}
        </View>
      )
    }else{
      return null
    }
  }

  //

  renderContent = () => {
    return(
      <View style={[{flex:1, width:'100%', backgroundColor:GLOBAL.style.backgroundColor}, MainStyles.flexStartCenter]}>
      {this.renderSection()}
      {this.renderModal()}
      </View>
    )
  }

  renderModal = () => {
    var { show_modal } = this.state

    var height = 540

    if(show_modal){
      return(
        <Pressable onPress={()=>this.setState({show_modal:false})} style={[{position:'absolute', top:0, left:0, width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.75)', zIndex:9999999}, MainStyles.flexCenter, MainStyles.flexRow]}>
          <View style={[{width:400, padding:GLOBAL.padding*2, height, borderRadius:GLOBAL.borderRadius, borderTopRightRadius:0, borderBottomRightRadius:0, backgroundColor:'white'}, MainStyles.flexCenterStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:24}}>
            Come join the ultimate platform for tennis fans!
            </Text>
            <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, fontSize:16, marginTop:GLOBAL.padding / 2, opacity:0.5}}>
            Built by fans, for fans
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding*2, textTransform:'uppercase', marginBottom:4, opacity:0.5}}>
            KEY FEATURES
            </Text>
            <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, lineHeight:24}}>
            {"- Personalized news, scores and stats\n- Get notifications everytime your favorites play\n- Live chats during matches with other fans\n- Fantasy bracket competitions for every tour event\n- Orders of play in your timezone\n- Live PDF draws"}
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding * 2}}>
            Download the TNNS app to find out why it's the highest rated app in tennis!
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding*2, textTransform:'uppercase', marginBottom:4}}>
            Download now!
            </Text>
            <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {this.renderDownloadButton('apple')}
            {this.renderDownloadButton('android')}
            </View>
          </View>
          <View style={[{height, width:500, padding:GLOBAL.padding, backgroundColor:"#13074d", borderTopRightRadius:GLOBAL.borderRadius, borderBottomRightRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
            <Image style={{width:'100%', aspectRatio:1, resizeMode:'contain', borderRadius:20}} source={require('../../assets/images/demosapp.webp')}/>
          </View>
        </Pressable>
        )
    }else{
      return null
    }
  }

  renderDownloadButton = (app) => {
    var image = ''
    var url = ''
    if(app === 'apple'){
      image = require('../../images/apple_app.png')
      url = "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724?platform=iphone"
    }else if(app === 'android'){
      url = "https://play.google.com/store/apps/details?id=com.tennisrn"
      image = require('../../images/google_app.png')
    }

    return(
      <Hoverable style={{}}>
      {({ hovered }) => (
        <Pressable onPress={()=>window.open(url, '_blank')}>
        <Image style={{height:52, aspectRatio:150/60, marginRight:GLOBAL.padding, resizeMode:'contain', opacity:hovered ? 0.8 : 1}} source={image}/>
        </Pressable>
      )}
      </Hoverable>

      )
  }

  renderSection = () => {
    var { loading } = this.state
    return(
      <>
      <NavBar navigation={this.props.navigation} page={this.props.page} onPressDownload={this.download}/>
      {
        // this.props.hideContent ? this.props.children :
        <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={[{width:'100%', marginTop:GLOBAL.displayMode === 'web' ? GLOBAL.padding*0.5 : 0}, MainStyles.flexStartCenter]}>
          <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
          {
            this.props.hideContent ? this.props.children :
            <>
            {
              <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth, paddingTop:GLOBAL.isMobile ? 0 : GLOBAL.padding/2, backgroundColor:'transparent', borderWidth:0, borderColor:GLOBAL.style.borderColor}, MainStyles.flexRow, MainStyles.flexStartStart]}>
                {
                  // GLOBAL.displayMode !== 'mobile' ? this.renderTournaments() : null
                }
                <View style={[{flex:1, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderColor:GLOBAL.style.borderColor, overflow:'hidden'}, MainStyles.flexCenter]}>
                {
                  !this.props.title ? null :
                  <View style={{width:'100%', padding:GLOBAL.padding, paddingBottom:0}}>
                  {this.renderHeader(this.props.title, this.props.icon)}
                  </View>
                }
                {this.props.children}
                </View>
                {
                  GLOBAL.displayMode === 'web' ? this.renderRightPanel() : null
                }
              </View>
            }
            {
              GLOBAL.displayMode !== 'web' && this.props.column ?
              <View style={{width:'100%', marginTop:GLOBAL.padding, borderWidth:0, backgroundColor:'transparent', borderRadius:GLOBAL.borderRadius, overflow:'hidden', borderColor:GLOBAL.style.borderColor}}>
              {this.props.column}
              </View>
               : null
            }
            </>
          }
          </View>
          {GLOBAL.isMobile || loading ? null : <Footer/>}
        </ScrollView>
      }
      </>
    )
  }

  renderRightPanel = () => {
    return(
      <View style={{borderWidth:1, borderLeftWidth:1, padding:GLOBAL.padding*1, paddingRight:GLOBAL.padding*0, width:340, minHeight:200, backgroundColor:GLOBAL.style.cardColor, marginLeft:8, marginTop:GLOBAL.padding*0, borderRadius:0, borderColor:GLOBAL.style.borderColor}}>
        {this.renderTournaments()}
        {this.renderNews()}
      </View>
    )
  }

  renderTournaments = () => {
    var { tournaments } = this.state
    return(
      <>
      {this.renderHeader('Active Tournaments', 'Tournaments')}
      {
        this.state.loading && tournaments.length === 0 ? this.renderLoading() :
        tournaments.map((item, index) => this.renderTournamentSection({item, index}))
      }
      </>
    )
  }

  renderTournamentSection = ({item, index}) => {
    var is_visible = index === 0 || this.state.show_all_tournaments
    if(is_visible){
      return(
        <View style={[{width:'100%', marginBottom:GLOBAL.padding, marginTop:GLOBAL.padding/2}, MainStyles.flexCenterStart]}>
          {
            <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, fontSize:11, opacity:0.67, textTransform:'uppercase', marginBottom:GLOBAL.padding/2}}>
            {item.title}
            </Text>
          }
          {item.data.map((item2, index2) => this.renderTournament({item:item2, index:index2}))}
        </View>
      )
    }else{return null}
  }

  renderTournament = ({item, index}) => {
    var logo = item.logo
    var onPress = ()=>GLOBAL.navigation.push('Tournament', {id:item.id})

    var size = 32

    var icon = 14

    var logos = item.logos || []

    return(
      <Hoverable style={{width:'100%'}}>
      {({ hovered }) => (
        <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, paddingVertical:GLOBAL.padding/1.5, marginLeft:-GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

          {
            // !logo ? null :
            // <Image
            // style={{width:size, height:size, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', backgroundColor:'rgba(0,0,0,0.01)'}}
            // source={{uri:logo}}
            // />
            // <Image
            // style={{width:icon, height:icon, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', tintColor:GLOBAL.style.accent || 'black', opacity:1}}
            // // source={{uri:logo}}
            // source={require('../../assets/icons/court.png')}
            // />
            logos.length === 0 ? null :
            <View style={[{marginRight:GLOBAL.padding/1.5, marginLeft:-2, marginTop:1}, MainStyles.flexCenter]}>
            {logos.map((item2,index2) => this.renderTournamentLogo({item:item2, index:index2}))}
            </View>
          }

          <View style={[{flex:1}, MainStyles.flexStartStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.medium, color:GLOBAL.style.color, fontSize:11, flex:1}}>
            {item.title}
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, textAlign:'left', marginTop:GLOBAL.padding/4, opacity:0.5}}>
            {item.subtitle_2}
            </Text>

            {
              // <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, textAlign:'left', marginTop:GLOBAL.padding/4, opacity:0.5}}>
              // {item.categories_string}
              // </Text>
              // logos.length === 0 ? null :
              // <View style={[{width:'100%', marginTop:GLOBAL.padding/2, marginLeft:-2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
              // {logos.map((item2,index2) => this.renderTournamentLogo({item:item2, index:index2}))}
              // </View>
            }
          </View>

          {
            // !logo ? null :
            // <Image
            // style={{width:size, height:size, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', backgroundColor:'rgba(0,0,0,0.01)'}}
            // source={{uri:logo}}
            // />
            // <Image
            // style={{width:icon, height:icon, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', tintColor:GLOBAL.style.accent || 'black', opacity:1}}
            // // source={{uri:logo}}
            // source={require('../../assets/icons/court.png')}
            // />
            // logos.length === 0 ? null :
            // <View style={[{marginRight:GLOBAL.padding/1.5, marginLeft:-2, marginTop:2}, MainStyles.flexCenterEnd]}>
            // {logos.map((item2,index2) => this.renderTournamentLogo({item:item2, index:index2}))}
            // </View>
          }

        </Pressable>
      )}
      </Hoverable>
    )
  }

  renderTournamentLogo = ({item, index}) => {
    return(
      <Image style={{width:36, aspectRatio:item.aspectRatio, resizeMode:'contain'}} source={{uri:item.url}}/>
    )
  }

  renderRankings = () => {
    var rankings = [
      {
        title:'Live ATP & WTA',
        data:[
          {title:'Live ATP Singles', link:"https://live-tennis.eu/en/atp-live-ranking", params:{tour:'atp', type:'official', format:'singles'}},
          {title:'Live ATP Singles Race', link:"https://live-tennis.eu/en/atp-race", params:{tour:'atp', type:'race', format:'singles'}},
          {title:'Live WTA Singles', link:"https://live-tennis.eu/en/wta-live-ranking", params:{tour:'wta', type:'official', format:'singles'}},
          {title:'Live WTA Singles Race', link:"https://live-tennis.eu/en/wta-race", params:{tour:'wta', type:'race', format:'singles'}},
        ]
      },
      {
        title:'Official ATP & WTA',
        data:[
          {title:'ATP Singles', params:{tour:'atp', type:'official', format:'singles'}},
          {title:'ATP Singles Race', params:{tour:'atp', type:'race', format:'singles'}},
          {title:'WTA Singles', params:{tour:'wta', type:'official', format:'singles'}},
          {title:'WTA Singles Race', params:{tour:'wta', type:'race', format:'singles'}},
          {title:'ATP Doubles', params:{tour:'atp', type:'official', format:'doubles'}},
          {title:'ATP Doubles Race', params:{tour:'atp', type:'race', format:'doubles'}},
          {title:'WTA Doubles', params:{tour:'wta', type:'official', format:'doubles'}},
          {title:'WTA Doubles Race', params:{tour:'wta', type:'race', format:'doubles'}},
        ]
      },
    ]
    return(
      <View style={{padding:GLOBAL.padding, marginTop:GLOBAL.padding/2, width:GLOBAL.column_width, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderRadius:0, borderColor:GLOBAL.style.borderColor}}>

        {this.renderHeader('Rankings', require('../../assets/icons/rankings.png'))}
        {
          rankings.map((item, index) => this.renderRankingSection({item, index}))
        }
      </View>
    )
  }

  renderRankingSection = ({item, index}) => {
    return(
      <View style={[{width:'100%', marginBottom:GLOBAL.padding}, MainStyles.flexCenterStart]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:12, textTransform:'uppercase', marginBottom:GLOBAL.padding/2}}>
        {item.title}
        </Text>
        {item.data.map((item2, index2) => this.renderRanking({item:item2, index:index2}))}
      </View>
    )
  }

  renderRanking = ({item, index}) => {
    var onPress = item.link ? ()=>Functions.openURL(item.link) : ()=>GLOBAL.navigation.push('Ranking', {tour:item.params.tour, format:item.params.format, type:item.params.type, title:item.title})
    var logos = []

    if(item.params.tour === 'atp'){
      logos = [{aspectRatio:1.5, url:GLOBAL.logos.atp}]
    }else if(item.params.tour === 'wta'){
      logos = [{aspectRatio:1.5, url:GLOBAL.logos.wta}]
    }

    return(
      <Hoverable style={{width:'100%'}}>
      {({ hovered }) => (
        <Pressable
        onPress={onPress}
        style={[{width:'100%', paddingVertical:GLOBAL.padding/1, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? GLOBAL.style.hover : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexStartCenter, MainStyles.flexRow]}>

        <View style={[{marginRight:GLOBAL.padding/2}, MainStyles.flexCenterStart]}>
          {logos ? logos.map((item2, index2) => this.renderTournamentLogo({item:item2, index:index2})) : null}
        </View>

          <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12}}>
          {item.title}
          </Text>

        </Pressable>
      )}
      </Hoverable>
    )
  }

  renderAppAd = () => {
    return(
      <View style={{padding:GLOBAL.padding, marginTop:GLOBAL.padding/2, width:GLOBAL.column_width, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderRadius:0, borderColor:GLOBAL.style.borderColor}}>

        {this.renderHeader('Get the TNNS app')}
        <Text>
        App ad - most popular app in tennis
        </Text>
      </View>
    )
  }

  renderQuickLinks = () => {
    return(
      <View style={{padding:GLOBAL.padding, marginTop:GLOBAL.padding/2, width:GLOBAL.column_width, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderRadius:0, borderColor:GLOBAL.style.borderColor}}>

        {this.renderHeader('Watch tennis live')}
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:GLOBAL.style.color, marginBottom:GLOBAL.padding}}>
        Most ATP Challenger & ITF matches can be streamed via TNNS.
        </Text>
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Button title="Find streams" onPress={()=>GLOBAL.navigation.push('Streams')} style={{width:10}}/>
        )}
        </Hoverable>
      </View>
    )
  }

  renderWidgets = () => {
    return(
      <View style={{padding:GLOBAL.padding, marginTop:GLOBAL.padding/2, width:GLOBAL.column_width, backgroundColor:GLOBAL.style.cardColor, borderWidth:1, borderRadius:0, borderColor:GLOBAL.style.borderColor}}>

        {this.renderHeader('Get live scores on your site', require('../../assets/icons/ball.png'))}
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:GLOBAL.style.color, marginBottom:GLOBAL.padding}}>
        Use one of our free live score widgets on your site - they're easy-to-embed front-end solutions that can be dropped into your site and instantly present live scores to your users.
        </Text>
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Button title="Get a widget"
          onPress={()=>GLOBAL.navigation.push('Support')}
          style={{opacity:hovered ? 0.85 : 1}}
          />
        )}
        </Hoverable>
      </View>
    )
  }

  renderNews = () => {
    var { news } = this.state
    return(
      <View style={{width:'100%', marginTop:GLOBAL.padding*2}}>
      {this.renderHeader('Headlines', 'News')}
      {
        this.state.loading && news.length === 0 ? this.renderLoading() :
        news.map((item, index) => this.renderNewsItem({item, index}))
      }
      </View>
    )
  }

  renderNewsItem = ({item, index}) => {
    var onPress = ()=>window.open(item.url, '_blank')

    var logo = item.image
    // var onPress = ()=>GLOBAL.navigation.push('Tournament', {id:item.id})

    var size = 14

    if(index === 0){
      var width = GLOBAL.column_width - 32
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, marginLeft:-GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexStartStart]}>

            <Image
            style={{width, height:width/1.5, borderRadius:4, overflow:'hidden', resizeMode:'cover', opacity:1}}
            source={{uri:logo}}
            // source={require('../../assets/icons/news0.png')}
            />

            <View onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, paddingVertical:GLOBAL.padding/1.5, paddingBottom:0, marginLeft:-GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

              {
                // !logo ? null :
                <Image
                style={{width:size, height:size, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', tintColor:GLOBAL.style.accent || 'black', opacity:1}}
                // source={{uri:logo}}
                source={require('../../assets/icons/news0.png')}
                />
              }

              <View style={[{flex:1}, MainStyles.flexStartStart]}>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, textAlign:'left', marginBottom:GLOBAL.padding/4, opacity:0.5}}>
                {item.header}
                </Text>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, flex:1}}>
                {item.text}
                </Text>
              </View>

            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }else{
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, paddingVertical:GLOBAL.padding/1.5, marginLeft:-GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

            {
              !logo ? null :
              <Image
              style={{width:size, height:size, borderRadius:4, overflow:'hidden', marginRight:GLOBAL.padding/1.5, resizeMode:'cover', tintColor:GLOBAL.style.accent || 'black', opacity:1}}
              // source={{uri:logo}}
              source={require('../../assets/icons/news0.png')}
              />
            }

            <View style={[{flex:1}, MainStyles.flexStartStart]}>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, textAlign:'left', marginBottom:GLOBAL.padding/4, opacity:0.5}}>
              {item.header}
              </Text>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:11, flex:1}}>
              {item.text}
              </Text>
            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }
  }

  renderHeader = (title, view_all) => {
    var size = 14
    return(
      <View style={[{width:'100%', marginBottom:GLOBAL.padding/2}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontSize:16, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.bold, flex:1}}>
        {title}
        </Text>
        {
          !view_all ? null :
          <Hoverable style={{}}>
          {({ hovered }) => (
            <Pressable
            onPress={()=>this.openPage(view_all)}
            style={{paddingHorizontal:GLOBAL.padding}}>

              <Text style={{fontFamily:GLOBAL.fonts.bold, color:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, fontSize:11, flex:1}}>
              View all
              </Text>

            </Pressable>
          )}
          </Hoverable>
        }
      </View>
    )
  }

  renderTournamentsOld = () => {
    return(
      <View style={{width:'100%', borderRadius:0, overflow:'hidden'}}>
        <Tournaments hideCalendar/>
      </View>
    )
  }

  renderPlayer = ({item, index}) => {
    return(
      <Player key={index} index={0} data={item}/>
    )
  }

  renderMatch = () => {
    return(
      <Text>Match</Text>
    )
  }

  renderTournamentOld = ({item, index}) => {
    return(
      <Tournament key={index} index={0} data={item}/>
    )
  }

  renderLoading = () => {
    return(
      <View style={[{width:'100%', height:200}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color='black'/>
      </View>
      )
  }


  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
