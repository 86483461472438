/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
} from 'react-native';

//Modules


//Components


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      item:this.props.item,
      index:this.props.index,
    }
  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.item !== this.props.item){
      this.setState({item:this.props.item})
    }
    if(prevProps.index !== this.props.index){
      this.setState({index:this.props.index})
    }
  }

  renderInside = (type, style, value) => {
    var render = this.renderNormal(style)
    if(type === 'past_meeting'){render = this.renderMeeting(style)}
    else if(value){render = this.renderSingleValue(style)}

    return render
  }

  renderNormal = (style) => {
    var { item, index } = this.state
    var { title, values, type } = item
    var { mode } = this.props

    var flex = 0.5
    if(type === 'bios'){flex = 0.25}

    if(mode === 'match_bios'){
      return(
        <>
        <Text style={[style, {flex:0.33, opacity:0.67}]}>
        {item?.title}
        </Text>

        {
          !values[0] && values[0] !== 0 ? null :
          <Text style={[style, {flex:0.33}]}>
          {values[0]}
          </Text>
        }

        {
          !values[1] && values[1] !== 0 ? null :
          <Text style={[style, {flex:0.33}]}>
          {values[1]}
          </Text>
        }
        </>
      )
    }else{
      return(
        <>
        <Text style={[style, {flex:1}]}>
        {item?.title}
        </Text>

        {
          !values ? null :
          <>
          {
            !values[0] && values[0] !== 0 ? null :
            <Text style={[style, {flex}]}>
            {values[0]}
            </Text>
          }

          {
            !values[1] && values[1] !== 0 ? null :
            <Text style={[style, {flex}]}>
            {values[1]}
            </Text>
          }
          </>
        }
        </>
      )
    }
  }

  renderSingleValue = (style) => {
    var { item, index } = this.state
    return(
      <>
      <Text style={[style, {flex:1, opacity:0.67}]}>
      {item?.title}
      </Text>

      <Text style={[style, {flex:1, textAlign:'right'}]}>
      {item.value}
      </Text>
      </>
    )
  }

  renderMeeting = (style) => {
    var { item, index } = this.state
    var { title, values, type } = item
    if(!values){return null}else{
      return(
        <View style={[{width:'100%'}, MainStyles.flexCenter]}>
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
            <Text style={[style,{flex:1}]} numberOfLines={1}>
            {values[0]}
            </Text>
            <Text style={[style,{}]}>
            {values[1]}
            </Text>
          </View>
          <View style={[{width:'100%', marginTop:GLOBAL.padding/2, opacity:0.5}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
            <Text style={[style,{flexShrink:1}]} numberOfLines={1}>
            {values[2]}
            </Text>
            <Text style={[style,{}]}>
            {values[3]}
            </Text>
          </View>
        </View>
      )
    }
  }

  render = () => {
    var { item, index } = this.state

    var { title, values, type, value } = item
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var flex = 0.5
    if(type === 'bios'){flex = 0.25}

    var style = {fontSize:12, textAlign:'left', flexWrap:'wrap', fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, paddingRight:4}


    try{
      return(
        <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1.5, backgroundColor:'transparent', borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

          {this.renderInside(type, style, value)}

        </View>
      )
    }catch(e){
      return null
    }
  }

}
